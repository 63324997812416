import PropTypes from 'prop-types'
import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {sso} from '@templates/Sso/sso-slice'
import {LanguageContext} from '@utils/context'
import {CommonProps} from '@utils/types'

/**
 * SSO
 */
interface SsoProps extends CommonProps {
	notRequiredPlaceholderProp?: string
}

const Sso: React.FC<SsoProps> = ({location}) => {
	const AUTH_TOKEN_QUERY_PARAM = 'token'
	const [authToken, setAuthToken] = useState<string>()
	const ssoSuccess = useSelector((state: RootState) => state.sso.success)

	const {activeLanguage} = useContext(LanguageContext)

	const dispatch = useDispatch()

	/**
	 * Parse auth redirect token on page load
	 */
	useEffect(() => {
		const {search} = location!
		const params = new URLSearchParams(search)
		const receivedAuthToken = params.get(AUTH_TOKEN_QUERY_PARAM)

		if (!receivedAuthToken || !receivedAuthToken.length) {
			return
		}

		setAuthToken(params.get(AUTH_TOKEN_QUERY_PARAM)!)
	}, [location])

	/**
	 * Get token through redux flow
	 */
	useEffect(() => {
		if (authToken) {
			dispatch(sso(authToken))
		}
	}, [dispatch, authToken])

	/**
	 * Navigate to home page on successful SSO
	 */
	useEffect(() => {
		if (ssoSuccess) {
			dispatch(navigate(`/${activeLanguage}/app`))
		}
	}, [ssoSuccess])

	return <div id="sso-container" />
}

Sso.propTypes = {
	notRequiredPlaceholderProp: PropTypes.any,
	location: PropTypes.any.isRequired,
}

export default Sso
